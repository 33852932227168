import refsCommon from "./refsCommon";
const { domains } = refsCommon;

document.addEventListener("DOMContentLoaded", async () => {
	if (!domains) return;
	try {
		domains.forEach((el) => (el.textContent = window.location.host));
	} catch (error) {
		throw new Error(error);
	}
});
